/* eslint-disable no-unused-vars */
import { firestoreAction } from 'vuexfire';
import { firestore, uploadImage } from '../firebase/database';
import { auth } from '../firebase/auth';

const actions = {
    bindUser: firestoreAction(async ({ bindFirestoreRef, commit, state }, userId) => {
        try {
            window.console.log("User ID to FEtch", userId);
            await bindFirestoreRef('user', firestore.collection('users').doc(userId), {reset: false});
            commit('updateAccessLevel', state.user.account_type);
            commit('updatePasswordRequirement', state.user.change_password);
        } catch (error) {
            window.console.log("User Fetch Error:", error.message);
        }
        //return bindFirestoreRef('user', firestore.collection('users').doc(userId), {reset: false});
    }),
    getTotalItems({ commit },) {
        let uid = auth.currentUser.uid;
        let listener = firestore.collection('users').doc(uid).collection('itemsCount').doc('data').onSnapshot((snapshot) => commit('updateItemsCount', snapshot.data()));
        commit("updateCountListener", listener);
    },
    writeUser({commit}, details) {
        firestore.collection('users').doc(details.user.uid).set(details.data, {merge: false});
        commit('');
    },
    activate({commit}, payload) {
        const {data, files, ...actions} = payload;
        const uid = auth.currentUser.uid;
        const regPath = `companyRegistration/${uid}/image.jpg`;
        const memoPath = `companyMemorandum/${uid}/image.jpg`;
        Promise.all([uploadImage(files.registration_document, regPath), uploadImage(files.memorandum_document, memoPath)]).then(async (results) => {
            const regUrl = results[0];
            const memoUrl = results[1];
            data.registration_document_url = regUrl;
            data.memorandum_document_url = memoUrl;
            data.account_activated = true;
            await firestore.collection('users').doc(uid).update(data);
            actions.closeAnimation();
            actions.close();
        }).catch((error) => {
            actions.closeAnimation();
        });
    },
    async updateBusinessDetails({ commit }, payload) {
        const uid = auth.currentUser.uid;
        await firestore.collection('users').doc(uid).update(payload);
    },
    async saveWebhook({commit}, payload) {
        const uid = auth.currentUser.uid;
        let hooks = {
            webhooks: [{...payload, type: 'payments'}]
        };
        await firestore.collection('users').doc(uid).update(hooks);
    },
    updateEnvironment({ commit }, payload) {
        const uid = auth.currentUser.uid;
        firestore.collection('users').doc(uid).update({environment: payload});
    },
    loadTransactions({commit}, payload) {
        setTimeout(() => {
            commit('updateDashboard', {
                chart_label: [],//[1611401671900, 1611401671900, 1611402671900, 1611403671900, 1611404671900, 1611405671900, 1611406671900, 1611607671900],
                chart_data: []//[25, 32, 17, 55, 32, 17, 0, 10,]
            });
            payload.close();
        }, 2000);
    }
}

export default actions;