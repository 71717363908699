import Vue from 'vue'
import Vuesax from 'vuesax'
import App from './App.vue'

import 'vuesax/dist/vuesax.css' //Vuesax styles
// import 'material-icons/iconfont/material-icons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const primaryColorLight = '#492540'; //#F26D5B
Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: primaryColorLight
    }
  }
})

// Vuex Store
import store from './store/store'

// Vue Router
import router from './router'

// Access control
//import acl from './acl/acl';

// import 'vue-feather-icons/dist/vue-feather-icons';

Vue.config.productionTip = false
Vue.config.devtools = true

import { auth } from './firebase/auth';

let app;

auth.onAuthStateChanged(async (currentUser) => {

  // Clear count listener.
  store.commit('clearCountListener');

  if (currentUser) {
    // let authResult = await currentUser.getIdTokenResult();
    // let accessLevel = authResult.claims.account_type;
    // let requirePasswordChange = authResult.claims.password_change_required;
    // window.console.log("Access:", accessLevel, "Password Change:", requirePasswordChange);
    // store.commit('updateAccessLevel', accessLevel);
    // store.commit('updatePasswordRequirement', requirePasswordChange);
    
    await store.dispatch('auth/fetchUser', currentUser.uid);
    store.dispatch('getTotalItems');
    // if (store.state.user && store.state.user.change_password) currentUser.getIdToken(true); 
  } else {
    router.push('/login');
  }

  if (!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App),
    }).$mount('#app');
  }
})


import '@/assets/scss/style.scss'