import { vuexfireMutations } from 'vuexfire';

const mutations = {
    ...vuexfireMutations,
    //This is for Sidbar trigger in mobile
    IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    updateAccessLevel(state, accessLevel) {
        state.accessLevel = accessLevel;
    },
    updatePasswordRequirement(state, passwordChange) {
        state.requirePasswordChange = passwordChange;
    },
    loadApp(state, value) {
        state.loaded = value;
    },
    updateItemsCount(state, payload) {
        window.console.log("Count update:", payload);
        state.paymentLinksCount = payload.payment_links_count;
        state.transactionsCount = payload.transactions_count;
        state.customersCount = payload.customers_count;
        state.settlementsCount = payload.settlements_count;
    },
    updateCountListener(state, value) {
        state.countListener = value;
    },
    clearCountListener(state) {
        window.console.log("Mutation to clear", state.countListener)
        state.countListener();
    },
    updateDashboard(state, value) {
        state.dashboard = {...state.dashboard, ...value};
    },
}

export default mutations;