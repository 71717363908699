import { auth, persistenceMode, EmailAuthProvider } from '../../firebase/auth';

const actions = {
    login({ commit }, input) {
        auth.setPersistence(persistenceMode).then(async () => {
            let result = await auth.signInWithEmailAndPassword(input.email, input.password);
            let authClaims = await result.user.getIdTokenResult();
            input.closeAnimation();
            input.open(authClaims.claims.password_change_required);
        }).catch((error) => {
            window.console.log("Login Error:", error);
            let result = {
                status: 'error',
                message: error.message
            }
            commit('updateStatus', result);
            input.closeAnimation();
        });
    },
    confirmEmail({commit}, input) {
        auth.sendSignInLinkToEmail(input.userDetails.user_email, {
            url: 'https://dashboard.utupay.co/register/complete-registration',
            handleCodeInApp: true,
            iOS: {
                bundleId: 'com.utupay.merchant'
            },
            android: {
                packageName: 'com.utupay.merchant',
                installApp: false,
                minimumVersion: '12'
            },
            dynamicLinkDomain: 'utp.utupay.co'
        }).then(() => {
            window.localStorage.setItem('utupayEmail', input.userDetails.user_email);
            input.closeAnimation();
            commit('updateStatus', {status: 'success', message: 'A verification link has been sent to your email. Click the link to complete your registration.'});
        }).catch((error) => {
            window.console.log("Confirm email Error:", error);
            let result = {
                status: 'error',
                message: error.message
            }
            commit('updateStatus', result);
            input.closeAnimation();
        })
    },
    register({ dispatch, commit }, input) {
        auth.setPersistence(persistenceMode).then(async () => {
            let {password, ...otherDetails} = input.userDetails;

            /**
             * TODO: Implement email&password credential signin linking with email signin with link.
             */
            //let emailLinkResult = await auth.signInWithEmailLink(otherDetails.user_email, window.location.href);

            // Including email and password authentication method.
            //let emailAuthCredential = EmailAuthProvider.credential(otherDetails.user_email, password);
            //let result = await emailLinkResult.user.linkWithCredential(emailAuthCredential);

            let result = await auth.createUserWithEmailAndPassword(otherDetails.user_email, password);
            let details = {
                data: {
                    user_id: result.user.uid,
                    ...otherDetails,
                    environment: 'test'
                },
                user: result.user
            }
            window.localStorage.removeItem('utupayEmail');
            
            await dispatch('writeUser', details, {root: true});
            input.closeAnimation();
            input.next();
        }).catch((error) => {
            window.console.log("Registration Error:", error);
            let result = {
                status: 'error',
                message: error.message
            }
            commit('updateStatus', result);
            input.closeAnimation();
        });
    },
    logout() {
        auth.signOut();
        //commit('signout');
    },
    async fetchUser({ dispatch, commit }, userId) {
        await dispatch('bindUser', userId, {root: true});
        commit('signin');
    },
    forgotPassword({ commit }, payload) {
        auth.sendPasswordResetEmail(payload.email).then(() => {
            let result = {
                status: 'success',
                message: 'Password reset instruction has been sent to your registered email.'
            }
            commit('updateStatus', result);
        }).catch((error) => {
            let result = {
                status: 'error',
                message: error.message
            }
            commit('updateStatus', result);
        }).then(() => payload.closeAnimation());
    },
    changePassword({ commit }, payload) {
        auth.currentUser.updatePassword(payload.password).then(() => {
            payload.closeAnimation();
            payload.success();
        }).catch((error) => {
            let result = {
                status: 'error',
                message: error.message
            }
            commit('updateStatus', result);
            payload.closeAnimation();
        })
    }
}

export default actions;