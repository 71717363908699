import state from './transactionStoreState';
import mutations from './transactionStoreMutations';
import actions from './transactionStoreActions';
import getters from './transactionStoreGetters';

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}