import state from './paymentLinkStoreState';
import mutations from './paymentLinkStoreMutations';
import actions from './paymentLinkStoreActions';
import getters from './paymentLinkStoreGetters';

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}