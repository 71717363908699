<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
    //import {auth} from './firebase/auth';
    import { mapState, mapMutations, mapActions } from 'vuex';

    export default {
      name: 'app',
      // created() {
      //     this.showLoading();
      //     auth.onAuthStateChanged( async (currentUser) => {
      //         //window.console.log("Auth State changed:", currentUser, "Current Route:", this.$router.currentRoute);
      //         if (currentUser) {
      //           let authResult = await currentUser.getIdTokenResult();
      //           let accessLevel = authResult.claims.account_type;
      //           let requirePasswordChange = authResult.claims.password_change_required;
      //           window.console.log("Access:", accessLevel, "Password Change:", requirePasswordChange);
      //           this.updateAccessLevel(accessLevel);
      //           this.updatePasswordRequirement(requirePasswordChange);
      //           this.stopLoading();
      //           this.loadApp(true);
      //         } else {
      //             this.stopLoading();
      //             this.$router.push('/login');
      //         }
      //     });
      // },
      computed: mapState(['user', 'loaded']),
      methods: {
        showLoading() {
          this.$vs.loading({
            type: 'point'
          });
        },
        stopLoading() {
          this.$vs.loading.close();
        },
        ...mapActions('auth', ['fetchUser']),
        ...mapMutations(['updateAccessLevel', 'updatePasswordRequirement', 'loadApp'])
      }
    }
</script>