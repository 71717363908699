import { firestore, uploadImage } from '../../firebase/database';
import types from '../types';
import { firestoreAction } from 'vuexfire';
import { auth } from '../../firebase/auth';

const actions = {
    bindCustomers: firestoreAction(async ({ bindFirestoreRef, commit }, payload) => {
        let uid = auth.currentUser.uid;
        try {
            await bindFirestoreRef('customers', firestore.collection('users').doc(uid).collection('customers').orderBy('creation_date').limit(5), {reset: true});
            payload.close();
        } catch (error) {
            window.console.log("Fetch Error:", error.message);
            commit(types.DATA_FETCH_ERROR, {message: error.message, status: 'error'});
            payload.close();
        }
    }),
    bindMoreCustomers: firestoreAction(async ({ bindFirestoreRef, commit }, payload) => {
        let uid = auth.currentUser.uid;
        try {
            window.console.log("Last Id:", payload.lastSnapshot.id);
            return bindFirestoreRef('customers', firestore.collection('users').doc(uid).collection('customers').orderBy('creation_date').startAfter(payload.lastSnapshot.creation_date).limit(5), {reset: false});
            //window.console.log("New Data:", newData);
        } catch (error) {
            window.console.log("Fetch Error:", error.message);
            commit(types.DATA_FETCH_ERROR, {message: error.message, status: 'error'});
        }
    }),
    createNewCustomer({commit}, payload) {
        let uid = auth.currentUser.uid;
        firestore.collection('users').doc(uid).collection('customers').add(payload.data).then(() => {
            payload.closeAnimation();
            payload.close();
        }).catch((error) => {
            commit(types.PAYMENT_LINK_CREATION_ERROR, {message: error.message, status: 'error'});
        })
    }
}

export default actions;