import { firestore, uploadImage } from '../../firebase/database';
import types from '../types';
import { firestoreAction } from 'vuexfire';
import { auth } from '../../firebase/auth';

const actions = {
    createSectorHead({ commit }, payload) {
        let output = {};
        let id = firestore.collection('users').doc().id;
        uploadImage(payload.image, `sectorHeads/${id}/image.png`).then(async (url) => {
            window.console.log("URL:", url, 'DATA:', payload.data);
            payload.data.avatar = url;
            await firestore.collection('users').doc(id).set(payload.data);
            output.status = 'success';
            //payload.close();
            payload.proceed();
            commit(types.SECTOR_HEAD_UPLOAD_STATUS, output);
        }).catch((error) => {
            payload.close();
            output.status = 'error';
            output.message = error.message;
            commit(types.SECTOR_HEAD_UPLOAD_STATUS, output);
        })
        
    },
    bindPaymentLinks: firestoreAction(async ({ bindFirestoreRef, commit }, payload) => {
        let uid = auth.currentUser.uid;
        try {
            await bindFirestoreRef('paymentLinks', firestore.collection('users').doc(uid).collection('paymentLinks').orderBy('creation_date').limit(5), {reset: true});
            payload.close();
        } catch (error) {
            window.console.log("Fetch Error:", error.message);
            commit(types.DATA_FETCH_ERROR, {message: error.message, status: 'error'});
            payload.close();
        }
    }),
    bindMorePaymentLinks: firestoreAction(async ({ bindFirestoreRef, commit }, payload) => {
        let uid = auth.currentUser.uid;
        try {
            window.console.log("Last Id:", payload.lastSnapshot.id);
            return bindFirestoreRef('paymentLinks', firestore.collection('users').doc(uid).collection('paymentLinks').orderBy('creation_date').startAfter(payload.lastSnapshot.creation_date).limit(5), {reset: false});
            //window.console.log("New Data:", newData);
        } catch (error) {
            window.console.log("Fetch Error:", error.message);
            commit(types.DATA_FETCH_ERROR, {message: error.message, status: 'error'});
        }
    }),
    createPaymentLink({commit}, payload) {
        let uid = auth.currentUser.uid;
        firestore.collection('users').doc(uid).collection('paymentLinks').add(payload.data).then(() => {
            payload.closeAnimation();
            payload.close();
        }).catch((error) => {
            commit(types.PAYMENT_LINK_CREATION_ERROR, {message: error.message, status: 'error'});
        })
    }
}

export default actions;