import firebase from "firebase/app";

const defaultConfig = {
    apiKey: "AIzaSyATsryWbkqq-_8blubrCqfrzBDbNMH73no",
    authDomain: "utupay-merchant.firebaseapp.com",
    projectId: "utupay-merchant",
    storageBucket: "utupay-merchant.appspot.com",
    messagingSenderId: "365513197235",
    appId: "1:365513197235:web:5af21c931440b6124312c6",
    measurementId: "G-H4PVQ75HL3"
};

// const employeeConfig = {

// }

const defaultApp = firebase.initializeApp(defaultConfig);
//const employeeApp = firebase.initializeApp(employeeConfig, "Employee");


export { defaultApp, firebase };