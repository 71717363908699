import state from './settlementStoreState';
import mutations from './settlementStoreMutations';
import actions from './settlementStoreActions';
import getters from './settlementStoreGetters';

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}