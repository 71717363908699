import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import AuthModule from './auth/AuthStoreModule';
import paymentLinkModule from './paymentLink/paymentLinkStoreModule';
import transactionModule from './transaction/transactionStoreModule';
import customerModule from './customer/customerStoreModule';
import settlementModule from './settlement/settlementStoreModule';

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: AuthModule,
    paymentLink: paymentLinkModule,
    transaction: transactionModule,
    customer: customerModule,
    settlement: settlementModule
  }
});