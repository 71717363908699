import types from "../types";

const mutations = {
    [types.SECTOR_HEAD_UPLOAD_STATUS](state, payload) {
        state.status = payload.status;
        state.message = payload.message;
    },
    [types.DATA_FETCH_ERROR](state, payload) {
        state.status = payload.status;
        state.message = payload.message;
    },
    [types.PAYMENT_LINK_CREATION_ERROR](state, payload) {
        state.status = payload.status;
        state.message = payload.message;
    },
    [types.PAYMENT_LINK_SIZE](state, payload) {
        state.paymentLinkCount = payload;
    }
}

export default mutations;