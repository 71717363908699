const mutations = {
    signin(state) {
        state.isLoggedIn = true;
    },
    signout(state) {
        state.isLoggedIn = false;
    },
    updateStatus(state, payload) {
        state.status = payload.status;
        state.message = payload.message;
    },
    reset(state) {
        state.status = '';
        state.message = '';
    }
}

export default mutations;