import state from './AuthStoreState';
import mutations from './AuthStoreMutations';
import actions from './AuthStoreActions';
import getters from './AuthStoreGetters';

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}