import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store';
import authorize from './acl/acl';
import { auth } from './firebase/auth';

Vue.use(Router)

const router = new Router({
    mode: 'history',
	routes: [
        {
            path: '',
            redirect: '/app'
        },
        {
            // ======================
            // Full Layout
            // ======================
            path: '/app',
            component: () => import('./layout/main/MainContainer.vue'),
            // ======================
            // Theme routes / pages
            // ======================
            children: [
                {
                    path: '',
                    name: 'Dashboard',
                    index: 1,
                    component: () => import('./views/Dashboard.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/transactions',
                    name: 'Transactions',
                    index: 2,
                    component: () => import('./views/components/utu/transactions/Transactions.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/customers',
                    name: 'Customers',
                    index: 3,
                    component: () => import('./views/components/utu/customers/Customers.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/balance',
                    name: 'Balance',
                    index: 4,
                    component: () => import('./views/components/utu/balance/Balance.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/settlements',
                    name: 'Settlements',
                    index: 5,
                    component: () => import('./views/components/utu/settlements/Settlements.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/payment-links',
                    name: 'PaymentLinks',
                    index: 6,
                    component: () => import('./views/components/utu/payment-links/PaymentLinks.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/qr-code',
                    name: 'QrCode',
                    index: 7,
                    component: () => import('./views/components/utu/qr-code/QrCode.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/account',
                    name: 'Account',
                    index: 8,
                    component: () => import('./views/components/utu/account/Account.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/settings',
                    name: 'Settings',
                    index: 9,
                    component: () => import('./views/components/utu/settings/Settings.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/activation',
                    name: 'Activation',
                    component: () => import('./views/Activation.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/app/transactions/transaction-details',
                    name: 'TransactionDetails',
                    component: () => import('./views/components/utu/transactions/TransactionDetails.vue'),
                    props: route => route.params,
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    path: '/app/payment-links/link-details',
                    name: 'PaymentLinkDetails',
                    component: () => import('./views/components/utu/payment-links/PaymentLinkDetails.vue'),
                    props: route => route.params,
                    meta: {
                        requiresAuth: false
                    }
                }
            ]
        },
        {
            path: '',
            component: () => import('./layout/pages/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    component: () => import('./layout/pages/Login.vue'),
                    children: [
                        {
                            path: '',
                            component: () => import('./layout/pages/components/LoginComponent.vue'),
                            meta: {
                                requiresAuth: false
                            }
                        },
                        {
                            path: 'forgot-password',
                            component: () => import('./layout/pages/components/ForgotPassword.vue'),
                            meta: {
                                requiresAuth: false
                            }
                        },
                        {
                            path: 'change-password',
                            component: () => import('./layout/pages/components/ChangePassword.vue'),
                            meta: {
                                requiresAuth: false
                            }
                        }
                    ]
                },
                {
                    path: '/unauthorized',
                    name: 'Unauthorized',
                    component: () => import('./layout/pages/Unauthorized.vue'),
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    path: '/register',
                    component: () => import('./layout/pages/Register.vue'),
                    children: [
                        {
                            path: '',
                            component: () => import('./layout/pages/components/ConfirmEmail.vue'),
                            meta: {
                                requiresAuth: false
                            }
                        },
                        {
                            path: 'complete-registration',
                            component: () => import('./layout/pages/components/CompleteRegistration.vue'),
                            props: true,
                            meta: {
                                requiresAuth: false
                            }
                        }
                    ]
                },
            ]
        },
        // Redirect to unauthorized page, if no match found
        {
            path: '*',
            redirect: '/unauthorized'
        }
    ]
});

router.beforeEach((to, from, next) => {
    // window.console.log("TO:", to.path, 'FROM:', from.path);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.currentUser) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;