import state from './customerStoreState';
import mutations from './customerStoreMutations';
import actions from './customerStoreActions';
import getters from './customerStoreGetters';

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}