const state = {
    loaded: false,
    user: {},
    accessLevel: '',
    requirePasswordChange: false,
    isSidebarActive: false,
    themeColor: "#2962ff",
    managersList: [],
    paymentLinksCount: 0,
    transactionsCount: 0,
    customersCount: 0,
    settlementsCount: 0,
    countListener: () => null,
    dashboard: {
        scale: 'day',
        current_month_earnings: 500,
        current_month_transactions: 0,
        wallet_balance: 0,
        total_income: 0,
        total_settlements: 0,
        total_customers: 0,
        chart_label: [],
        chart_data: [],
        insight_label: [0,1],
        insight_data: [1, 1]
    }
}

export default state;