import "firebase/firestore";
import "firebase/storage";

import { defaultApp } from './config';

const firestore = defaultApp.firestore();
const storage = defaultApp.storage();

const uploadImage = (dataUrl, path) => {
    return new Promise(async (resolve, reject) => {
        let ref = storage.ref().child(path);
        try {
            let snapshot = await ref.putString(dataUrl, 'data_url', {contentType: 'image/png'})
            let downloadURL = snapshot.ref.getDownloadURL();
            resolve(downloadURL);
        } catch (error) {
            reject(error);
        }
    })
}

export { firestore, uploadImage };